import React from 'react';
import { Link } from 'gatsby';
import { UserContext } from 'utils/firebase';

import theme from 'styles/theme';
import Loader from 'components/Loader';

import logoImage from 'img/logo-lab-light.svg';

import {
  Wrapper,
  Inner,
  Logo,
  Nav,
  PrimaryButton,
  Details,
  Name,
  Email,
  User
} from './styles';

class Header extends React.Component {
  static contextType = UserContext;

  render() {
    const firebase = this.context;

    if (!firebase.user) {
      return null;
    }

    return (
      <Wrapper role="navigation" aria-label="main-navigation">
        <Inner>
          <Link to="/" title="Logo">
            <Logo src={logoImage} alt="see6" />
          </Link>
          <Nav>
            <User>
              {firebase.user ? (
                <>
                  <Details>
                    <Name>{firebase.user.displayName}</Name>
                    <Email>{firebase.user.email}</Email>
                  </Details>
                </>
              ) : (
                <Loader size="35px" color={theme.color.teal} />
              )}
            </User>
            <PrimaryButton onClick={firebase.logOut}>Logout</PrimaryButton>
          </Nav>
        </Inner>
      </Wrapper>
    );
  }
}

export default Header;
