import styled from 'styled-components';
import { transparentize } from 'polished';
import { space } from 'styled-system';
import { Link } from 'gatsby';

import theme from 'styles/theme';

import { Text } from 'components/Text';
import { Container } from 'components/Grid';

export const NavButton = styled(Link)`
  margin-left: 25px;
  font-size: ${theme.font.size.epsilon};

  @media (max-width: ${theme.size.tablet}) {
    margin-left: 0;
    margin-right: 25px;
  }
`;

export const Wrapper = styled.footer`
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;

  * {
    -webkit-font-smoothing: subpixel-antialiased;
  }
`;

export const Inner = styled.div`
  padding-top: 30px;
  padding-bottom: 60px;

  ${space};
`;

export const Main = styled(Container)`
  justify-content: flex-start;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40px 0;

  @media (max-width: ${theme.size.tablet}) {
    flex-direction: column;
  }
`;

export const Logo = styled.img`
  height: 20px;
  width: auto;
  transition: ${theme.transition};

  &:hover {
    opacity: 0.7;
  }
`;

export const Slogan = styled(Text)`
  color: ${theme.color.teal};
`;

export const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: ${theme.size.tablet}) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const Legal = styled(Container)`
  opacity: 0.7;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: ${theme.font.size.epsilon};
  color: ${props => (props.hasImage ? 'white' : theme.color.gray)};

  a {
    margin-left: 25px;
  }

  @media (max-width: ${theme.size.tablet}) {
    padding-top: 0;
    flex-direction: column;

    a {
      margin: 0 25px 0 0;
    }
  }
`;

export const Address = styled.ul`
  width: 50%;
  list-style: none;
  margin-top: 0;
  font-size: ${theme.font.size.epsilon};

  @media (max-width: ${theme.size.tablet}) {
    padding-left: 0;
    padding: 30px 30px 0 0;
  }

  @media (max-width: ${theme.size.mobile}) {
    padding-right: 30px;
    width: 100%;
  }

  h5 {
    font-weight: ${theme.font.weight.bold};
    color: ${props =>
      props.hasImage || props.shade === 'dark' ? 'white' : theme.color.indigo};
  }

  li {
    margin-bottom: 0;
    color: ${props =>
      props.hasImage ? transparentize(0.3, 'white') : theme.color.gray};
  }

  a {
    display: table;
    margin-top: 10px;
  }
`;
