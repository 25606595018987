const theme = {
  color: {
    teal: '#0994C6',
    purple: '#39225A',
    indigo: '#0D0925',
    indigo2: '#141034',
    indigo3: '#28234F',
    indigo4: '#4C4872',
    yellow: '#EEA337',
    pink: '#803E85',
    green: '#5FB34D',
    blue: '#0994C6',
    red: '#D3373D',
    orange: '#E67630',
    gray: '#868D9F',
    gray2: '#60687C',
    ink: '#1F201F',
    paper: '#E7E8EB',
    paper2: '#F2F3F5'
  },

  font: {
    size: {
      tera: '110px',
      giga: '72px',
      mega: '58px',
      alpha: '48px',
      beta: '36px',
      cesar: '30px',
      gamma: '28px',
      theta: '24px',
      upsilon: '20px',
      delta: '18px',
      epsilon: '16px',
      zeta: '14px',
      kappa: '12px'
    },
    weight: {
      regular: 400,
      bold: 600
    }
  },

  size: {
    maxWidth: '1140px',
    desktop: '65em',
    tablet: '52em',
    mobile: '40em'
  },

  gutter: '30px',
  radius: '3px',
  transition: 'ease-in-out 0.2s'
};

export default theme;
