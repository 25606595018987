import React, { useState, useContext } from 'react';
import { FirebaseContext, useFirebase } from 'gatsby-plugin-firebase';

const UserContext = React.createContext(null);

const AddData = ({ children }) => {
  const [user, setUser] = useState();
  const [refUser, setRefUser] = useState();
  const firebase = useContext(FirebaseContext);
  const [readyToFetch, setReadyToFetch] = useState(false);
  const [error, setError] = useState();

  useFirebase(firebase => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
        getUserRef(user);
      } else {
        setUser(null);
        setRefUser(null);
        setReadyToFetch(true);
      }
    });
  }, []);

  function getUserRef(user) {
    firebase
      .database()
      .ref('users/' + user.uid)
      .on('value', snapshot => {
        setRefUser(snapshot.val());
        setReadyToFetch(true);
      });
  }

  const signUp = (email, password, displayName) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(res => {
        firebase
          .database()
          .ref('users/' + res.user.uid)
          .update({
            displayName: displayName,
            email: res.user.email,
            role: 'user',
            isActive: true
          });

        return res;
      })
      .then(res => {
        res.user.updateProfile({
          displayName: displayName
        });
      })
      .catch(error => {
        return setError(error.message);
      });
  };

  const logIn = (email, password) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        firebase
          .database()
          .ref('users/' + res.user.uid)
          .on('value', snapshot => {
            const user = snapshot.val();
            if (!user.isActive) {
              logOut(user);
            }
          });
      })
      .catch(error => {
        return setError(error.message);
      });
  };

  const logOut = user => {
    firebase.auth().signOut();
  };

  const getUsers = () => {
    return firebase
      .database()
      .get()
      .ref('/users')
      .once('value')
      .then(snapshot => {
        setUser(snapshot.val());
      });
  };

  return (
    <UserContext.Provider
      value={{
        readyToFetch: readyToFetch,
        signUp: signUp,
        logIn: logIn,
        error: error,
        user: user,
        refUser: refUser,
        getUsers: getUsers,
        logOut: logOut
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default AddData;

export { UserContext };
