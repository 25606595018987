import styled from "styled-components";
import ReactMasonry from "react-masonry-css";
import {
  flex,
  color,
  space,
  width,
  order,
  height,
  minHeight,
  zIndex,
  display,
  borders,
  maxWidth,
  flexWrap,
  position,
  boxShadow,
  alignSelf,
  alignItems,
  justifySelf,
  flexDirection,
  justifyContent,
  background
} from "styled-system";

import theme from "styles/theme";

export const Grid = styled.div`
  margin: auto;
  position: relative;
  padding-left: ${theme.gutter};
  padding-right: ${theme.gutter};
  max-width: ${theme.size.maxWidth};

  @media (max-width: ${theme.size.tablet}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${width};
  ${space};
  ${color};
  ${zIndex};
  ${borders};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-image: ${props => props.image && `url(${props.image})`};

  ${flex};
  ${space};
  ${width};
  ${order};
  ${color};
  ${height};
  ${zIndex};
  ${borders};
  ${display};
  ${position};
  ${maxWidth};
  ${flexWrap};
  ${minHeight};
  ${boxShadow};
  ${alignSelf};
  ${alignItems};
  ${justifySelf};
  ${flexDirection};
  ${justifyContent};
  ${background};
`;

export const Masonry = styled(ReactMasonry)`
  display: flex;
  width: ${props => `calc(100% + ${props.gutter || "20px"})`};
  margin-left: ${props => `-${props.gutter || "20px"}`};

  ${flex};
  ${space};
  ${width};
  ${order};
  ${color};
  ${height};
  ${zIndex};
  ${borders};
  ${display};
  ${position};
  ${maxWidth};
  ${flexWrap};
  ${boxShadow};
  ${alignSelf};
  ${alignItems};
  ${justifySelf};
  ${flexDirection};
  ${justifyContent};

  .my-masonry-grid_column {
    background-clip: padding-box;
    padding-left: ${props => props.gutter || "20px"};
  }
`;

export const Block = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;

  ${flex};
  ${space};
  ${width};
  ${order};
  ${color};
  ${height};
  ${zIndex};
  ${borders};
  ${display};
  ${position};
  ${maxWidth};
  ${flexWrap};
  ${boxShadow};
  ${alignSelf};
  ${alignItems};
  ${justifySelf};
  ${flexDirection};
  ${justifyContent};
`;
