import React from 'react';

import { Text } from 'components/Text';
import { Grid } from 'components/Grid';

import { Wrapper, Inner, Legal } from './styles';

const Footer = ({ shade, image, ...props }) => {
  const year = new Date().getFullYear();

  return (
    <Wrapper shade={shade}>
      <Inner {...props}>
        <Grid zIndex="2">
          <Legal shade={shade}>
            <Text width={['100%', '100%', 'auto']}>© {year} see6</Text>
          </Legal>
        </Grid>
      </Inner>
    </Wrapper>
  );
};

export default Footer;
