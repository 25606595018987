import styled from 'styled-components';
import { Link } from 'gatsby';
import { lighten } from 'polished';

import theme from 'styles/theme';

import { Grid } from 'components/Grid';
import Button from 'components/Button';
import { Text } from 'components/Text';

export const Wrapper = styled.header`
  color: white;
  position: relative;
  z-index: 10;
  background-color: ${theme.color.indigo2};
  border-bottom: 1px solid ${theme.color.indigo3};

  @media (max-width: ${theme.size.tablet}) {
    height: auto;
  }
`;

export const NavButton = styled(Link)`
  margin-left: 25px;
  font-size: ${theme.font.size.epsilon};

  @media (max-width: ${theme.size.mobile}) {
    margin: 0;
    padding: 12px 20px 14px;
    width: 100%;
  }
`;

export const Inner = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 20px;
  justify-content: space-between;

  * {
    -webkit-font-smoothing: subpixel-antialiased;
  }
`;

export const Logo = styled.img`
  height: 40px;
  width: auto;
  transition: ${theme.transition};

  &:hover {
    opacity: 0.7;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  transition: ${theme.transition};
`;

export const PrimaryButton = styled(Button)`
  font-weight: ${theme.font.weight.regular};
  padding: 10px 14px 13px;
  color: white;
  margin-left: 0;
  border: none;
  text-align: left;
  background-color: ${theme.color.green};

  &:hover {
    opacity: 1;
    background-color: ${lighten(0.05, theme.color.green)};
  }

  @media (min-width: ${theme.size.mobile}) {
    font-size: ${theme.font.size.epsilon};
  }
`;

export const User = styled.div`
  padding-right: 30px;
  display: flex;
  align-items: center;

  @media (max-width: ${theme.size.mobile}) {
    display: none;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Name = styled(Text)`
  line-height: 1.2;
  font-weight: ${theme.font.weight.bold};
  font-size: ${theme.font.size.delta};

  @media (max-width: ${theme.size.mobile}) {
    display: none;
  }
`;

export const Email = styled(Text)`
  opacity: 0.5;
  font-size: ${theme.font.size.zeta};
  color: white;

  @media (max-width: ${theme.size.mobile}) {
    display: none;
  }
`;
