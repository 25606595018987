import styled from 'styled-components';
import styledMap from 'styled-map';
import { space, color, alignSelf, opacity } from 'styled-system';
import { transparentize, lighten } from 'polished';
import { Link } from 'gatsby';

import theme from 'styles/theme';

export const Wrapper = styled(Link)`
  line-height: 1;
  display: inline-block;
  position: relative;
  cursor: pointer;
  border: 1px solid;
  background-color: transparent;
  font-weight: ${theme.font.weight.bold};
  transition: ${theme.transition};
  border-radius: ${theme.radius};

  padding: ${styledMap('size', {
    small: '10px 12px 11px',
    default: '13px 22px 14px'
  })};

  font-size: ${styledMap('size', {
    small: theme.font.size.kappa,
    default: theme.font.size.zeta
  })};

  color: ${styledMap('shade', {
    yellow: 'white',
    pink: 'white',
    primary: 'white',
    light: 'white',
    default: theme.color.ink
  })};

  border-color: ${styledMap('shade', {
    yellow: 'transparent',
    pink: 'transparent',
    primary: 'transparent',
    light: transparentize(0.85, 'white'),
    default: transparentize(0.85, theme.color.ink)
  })};

  background-color: ${styledMap('shade', {
    yellow: theme.color.yellow,
    pink: theme.color.pink,
    primary: theme.color.teal,
    default: 'transparent'
  })};

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: ${styledMap('shade', {
      yellow: transparentize(0.5, 'white'),
      pink: transparentize(0.5, 'white'),
      primary: transparentize(0.5, 'white'),
      light: transparentize(0.5, 'white'),
      default: transparentize(0.5, theme.color.ink)
    })};

    background-color: ${styledMap('shade', {
      yellow: lighten(0.05, theme.color.yellow),
      pink: lighten(0.05, theme.color.pink),
      primary: lighten(0.05, theme.color.teal),
      default: 'transparent'
    })};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${space};
  ${color};
  ${opacity};
  ${alignSelf};
`;
