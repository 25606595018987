import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import AddData from 'utils/firebase';
import GlobalStyle from 'styles/global';
import Header from 'components/Header';
import Footer from 'components/Footer';

import touchIcon from 'img/apple-touch-icon.png';
import favicon32 from 'img/favicon-32x32.png';
import favicon16 from 'img/favicon-16x16.png';

import { Wrapper } from './styles';

const Layout = ({ content, shade, children }) => {
  const isEdge =
    typeof navigator !== 'undefined'
      ? navigator.appVersion.indexOf('Edge') !== -1
      : null;
  const isIE =
    typeof navigator !== 'undefined'
      ? navigator.userAgent.indexOf('MSIE') !== -1 ||
        navigator.appVersion.indexOf('Trident/') > -1
      : null;

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet>
            <html lang="en" class={`${isEdge ? 'edge' : isIE ? 'ie' : ''}`} />
            <title>
              {content && content.page.title ? `${content.page.title} | ` : ''}
              {data.site.siteMetadata.title}
            </title>
            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />
            <link rel="apple-touch-icon" sizes="180x180" href={touchIcon} />
            <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
            <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          </Helmet>
          <GlobalStyle shade={shade} />
          <AddData>
            <Header />
            <Wrapper>{children}</Wrapper>
            <Footer />
          </AddData>
        </>
      )}
    />
  );
};

export default Layout;
