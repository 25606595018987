import { createGlobalStyle } from 'styled-components';
import { normalize, darken } from 'polished';
import styledMap from 'styled-map';

import theme from './theme';
import {
  HKGroteskRegularWOFF2,
  HKGroteskRegularWOFF,
  HKGroteskBoldWOFF2,
  HKGroteskBoldWOFF
} from './fonts/fonts';

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  @font-face {
    font-family: "hkgrotesk";
    font-style: normal;
    font-weight: 400;
    src:
      url(${HKGroteskRegularWOFF2}) format('woff2'),
  	  url(${HKGroteskRegularWOFF}) format('woff');
  }

  @font-face {
    font-family: "hkgrotesk";
    font-style: normal;
    font-weight: 600;
    src:
      url(${HKGroteskBoldWOFF2}) format('woff2'),
  	  url(${HKGroteskBoldWOFF}) format('woff');
  }

  * {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: 'hkgrotesk', sans-serif !important;
    font-display: swap;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  html,
  body {
    margin: 0;
    width: 100%;
    min-height: 100%;
  }

	body {
    line-height: 1.6;
    -webkit-text-size-adjust: none;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: ${theme.font.size.delta};

    color: ${styledMap('shade', {
      dark: 'white',
      default: theme.color.indigo
    })};

    background-color: ${styledMap('shade', {
      dark: theme.color.indigo,
      paper: theme.color.paper2,
      default: 'white'
    })};
	}

  a {
    text-decoration: none;
    transition: ${theme.transition};

    p & {
      color: ${theme.color.teal};

      &:hover {
        color: ${darken(0.1, theme.color.teal)};
      }
    }
  }

  hr {
    margin: 40px auto;
    border: none;
    border-top: 1px solid ${theme.color.paper};
  }

  strong {
    color: inherit;
    font-weight: ${theme.font.weight.bold};
  }

  p, li {
    margin-top: 0;
    margin-bottom: 15px;
  }
`;

export default GlobalStyle;
